export default {
  namespaced: true,
  name: 'product_operations',

  translatedAttributes: [],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => ({
    ...item,
  }),

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => {
    return {
      ...initialItemData,
      ...data,
    }
  },

  resolveValidationErrors: errors => ({
    ...errors,
  }),
}

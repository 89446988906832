import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import productOperationModel from '@/views/models/products/productOperationModel'

const {
  fetchOne,
  create,
} = useModelStoreModule(productOperationModel, true)

export default {
  name: 'product_operations',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOne,
    create,
  },
}

<template>

  <div>
    <!-- Filters -->
    <filters
      :category-filter.sync="categoryFilter"
      :status-filter.sync="statusFilter"
    />

    <!-- Table Container Card -->
    <model-list-table
      :store-module-name="storeModuleName"
      :table-columns="tableColumns"
      :filters-list="filtersList"
      :query-params="getQueryParams"
      :add-action="true"
      :view-action="false"
      :preview-action="true"
      :edit-action="true"
      :delete-action="true"
      :extra-actions="[]"
      :is-sortable="false"
    >
      <template #extra-container-actions="{isSortingActive, isTableBusy}">
        <b-dropdown
          v-if="!isSortingActive"
          right
          variant="info"
          class="mr-1"
          :disabled="isTableBusy || isProductOperationRequestLoading"
        >
          <template #button-content>
            <material-icon
              icon="sync_alt"
              class="mr-50"
              size="16"
            />
            <span
              v-t="`modules.products.actions.export_and_import`"
              class="align-middle text-nowrap"
            />
          </template>
          <b-dropdown-item @click="onClickExport">
            <feather-icon
              :icon="$i18n.locale === 'ar' ? 'ArrowUpRightIcon' : 'ArrowUpLeftIcon'"
            />
            {{ $t('modules.products.actions.export_csv') }}
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item v-b-modal.modal-import-products>
            <feather-icon
              :icon="$i18n.locale === 'ar' ? 'ArrowDownLeftIcon' : 'ArrowDownRightIcon'"
            />
            {{ $t('modules.products.actions.import_csv') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </model-list-table>

    <import-products-modal @operation-finished="onProductOperationFinished" />
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted } from 'vue'
import { formatDate, priceText } from '@core/utils/filter'
import ModelListTable from '@/views/models/common/ModelListTable.vue'
import {
  BDropdown, BDropdownDivider, BDropdownItem, VBModal,
} from 'bootstrap-vue'
import { getLocale } from '@core/utils/utils'
import productOperationStoreModule from '@/views/models/products/productOperationStoreModule'
import { saveAs } from 'file-saver'
import ImportProductsModal from '@/views/models/products/list/ImportProductsModal.vue'
import Filters from './Filters.vue'
import productStoreModule from '../productStoreModule'

export default {
  components: {
    ImportProductsModal,
    BDropdownDivider,
    BDropdownItem,
    BDropdown,
    Filters,
    ModelListTable,
  },
  directives: {
    'b-modal': VBModal,
  },

  methods: {
    getLocale,
  },
  setup() {
    const STORE_MODULE_NAME = 'products'
    const OPERATIONS_STORE_MODULE_NAME = 'product_operations'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, productStoreModule)
    if (!store.hasModule(OPERATIONS_STORE_MODULE_NAME)) store.registerModule(OPERATIONS_STORE_MODULE_NAME, productOperationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      if (store.hasModule(OPERATIONS_STORE_MODULE_NAME)) store.unregisterModule(OPERATIONS_STORE_MODULE_NAME)
    })

    const categoryFilter = ref(null)
    const statusFilter = ref(null)
    const isProductOperationRequestLoading = ref(false)

    const tableColumns = [
      // {
      //   key: 'draggable',
      //   label: '',
      //   sortable: false,
      //   thClass: 'p-0',
      //   thStyle: 'width: 1px',
      //   tdClass: ['p-0', 'pl-1', 'draggable-handle', 'cursor-move'],
      // },
      {
        key: 'checkbox',
        label: '',
        sortable: false,
      },
      // { key: 'id', sortable: true },
      {
        key: 'product', sortable: true, label: 'Name', formatter: priceText,
      },
      {
        key: 'price', sortable: true, label: 'Regular Price', formatter: priceText,
      },
      {
        key: 'sale_price', sortable: true, label: 'Sale Price', formatter: priceText,
      },
      {
        key: 'created_at',
        label: 'Creation Date',
        formatter: formatDate,
        sortable: true,
      },
      {
        key: 'updated_at',
        label: 'Last Update',
        formatter: formatDate,
        sortable: true,
      },
      { key: 'is_active', sortable: true, label: 'Status' },
      { key: 'actions' },
    ]

    const filtersList = [categoryFilter, statusFilter]
    const getQueryParams = () => ({
      categories: categoryFilter.value,
      is_active: statusFilter.value,
    })

    const onClickExport = () => {
      isProductOperationRequestLoading.value = true

      store.dispatch(`${OPERATIONS_STORE_MODULE_NAME}/create`, { operation: 'export' })
        .then(response => { saveAs(response.file_path, 'kssib-products.csv') })
        .finally(() => { isProductOperationRequestLoading.value = false })
    }
    const onClickImport = () => {
      saveAs('@/assets/files/sample.csv', 'kssib-sample.csv')
    }
    const onProductOperationFinished = () => {
      window.location.reload()
    }

    return {
      isProductOperationRequestLoading,

      onClickExport,
      onClickImport,
      onProductOperationFinished,

      // Extra Filters
      categoryFilter,
      statusFilter,

      // Table props
      tableColumns,
      storeModuleName: STORE_MODULE_NAME,
      filtersList,
      getQueryParams,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">

$gray-300: #dee2e6 !default;
$border-color: $gray-300 !default;

::v-deep .b-table {
  tbody {
    tr {
      //cursor: move;
    }
  }
}

.b-table {
  tbody {
    tr.ready-for-drag {
      transform: translateY(-4px);
      box-shadow: 0 3px 10px 0 $border-color;
      transition: all 0.2s;
    }
    tr {
      &:has(td .draggable-handle) {
        transform: translateY(-4px);
        box-shadow: 0 3px 10px 0 $border-color;
        transition: all 0.2s;
      }
    }
  }
}

.btn-link:hover {
  color: #5d6064 !important;
}
</style>
